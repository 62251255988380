
import { Vue, Component, Mixins } from "vue-property-decorator";
import { mapState } from "vuex";
import { K12School } from "@/models/interface";
import InfoModal from "@/components/InfoModal.vue";
import SchoolMixin from "@/mixins/school.mixin";
import moment from "moment";

/*@Component({
  components: {
    InfoModal,
  },
  computed: { ...mapState("app", ["school", "mobileView", "tabletView"]) },
  metaInfo: {
    title: "Inspections",
  },
})*/
@Component({
  computed: { ...mapState("app", ["school", "mobileView", "tabletView"]) },
  filters: {
    formatDate(date: Date | string) {
      return moment(date).format("MM-DD-YYYY");
    }
  },
})
export default class Inspections extends Mixins(SchoolMixin) {
  public inspectionarray = []; 
  private inspectionCurrentPage = 1;
  private inspectionsPerPage = 10;
  private filter = null;
  private sortDesc = true;
  private sortDirection = "asc";
  private sortBy = "tempName";
  //private sortBy = "inspectionDate";
  private inspectionSortBy = "tempName";

  async created() {
    await this.FacilityInspections();
  }
  async FacilityInspections() {
    this.$store
      .dispatch(
        "app/getInspectionVisits",
        this.schoolAbout.earlyEdLicenseNumber
      )
      .then((result: any) => {
        this.inspectionarray = result;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  get inspectionPageOptions() {
    return [
      { value: 10, text: "Show 10 per page" },
      { value: 20, text: "Show 20 per page" },
      { value: 30, text: "Show 30 per page" },
      { value: 40, text: "Show 40 per page" },
      { value: 50, text: "Show 50 per page" },
      { value: this.inspectionarray.length, text: "Show All" },
    ];
  }
 
  private inspectionfields = [
    {
      key: "inspectionDate",
      label: "Inspection Date",
      sortable: true,
      class: "text-left align-left",
    },
    {
      key: "inspectionType",
      label: "Inspection Type",
      sortable: false,
      class: "text-left align-middle",
    },
    {
      key: "docPopUrl",
      label: "Details",
      sortable: false,
      class: "text-left align-middle",
    },
  ];
  get inspectionPageCount(): number | string | undefined | null {
    if (this.inspectionarray && this.inspectionarray.length > 0) {
      const len = this.inspectionarray.length;
      console.log(`Per Page Value: ${this.inspectionsPerPage}`);
      if (len < this.inspectionsPerPage) {
        return this.inspectionCurrentPage;
      } else {
        if (this.inspectionsPerPage === 0) {
          return 1;
        }
        const pgcount = Math.ceil(len / this.inspectionsPerPage);
        return pgcount;
      }
    } else {
      return this.inspectionCurrentPage;
    }
  }
  get inspectionTotalRows() {
    return this.inspectionarray?.length;
  }
  onInspectionSortBy(column) {
    this.inspectionSortBy = column;
  }

  momentFormatDate(val: Date | string) 
  {
    if (!val || val === null) 
    {
      return "";
    } 
    else 
    {
      return moment(val).format("MM-DD-YYYY");
    }
  }

  onsortBy(column) 
  {
    this.sortBy = column;
  }

  sortByDate(a, b) 
  {
      const date1 = new Date(a.inspectionDate).getTime();
      const date2 = new Date(b.inspectionDate).getTime();      
      return date1 - date2;
  }
}
